body {
  font-family: "Inter" !important;
  height: 100vh;
}
.gradientText {
  background: -webkit-linear-gradient(292deg, #0f3ac0 0%, #2ed1f3 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.side_bar::-webkit-scrollbar {
  height: 6px;
  width: 5px;
}

/* Track */
.side_bar::-webkit-scrollbar-track {
  border-radius: 2px;
}

/* Handle */
.side_bar::-webkit-scrollbar-thumb {
  background: rgba(224, 224, 224, 0.925);
  border-radius: 2px;
}

/* Handle on hover */
.side_bar::-webkit-scrollbar-thumb:hover {
  background: rgb(151, 151, 151);
}


.edit-week-days::-webkit-scrollbar {
  height: 6px;
  width: 4px;
}

/* Track */
.edit-week-days::-webkit-scrollbar-track {
  border-radius: 2px;
}

/* Handle */
.edit-week-days::-webkit-scrollbar-thumb {
  background: rgba(224, 224, 224, 0.925);
  border-radius: 2px;
}

/* Handle on hover */
.edit-week-days::-webkit-scrollbar-thumb:hover {
  background: rgb(151, 151, 151);
}

.headCard {
  transition: box-shadow 0.3s ease, transform 0.2s ease;
}

/*headCard footer*/
.headCard-social {
  transform: translateY(200%);
  display: flex;
  justify-content: space-around;
  width: 100%;
  opacity: 0;
  transition: transform 0.2s ease, opacity 0.2s ease;
}

.headCard-social__item {
  list-style: none;
}

.headCard:hover .headCard-info {
  transform: translateY(-5%);
}

.headCard:hover .headCard-social {
  transform: translateY(100%);
  opacity: 1;
}

.headCard-social__item svg:hover {
  fill: #232323;
  transform: scale(1.1);
}

.wrapper {
  position: relative;
  overflow-x: hidden;
  max-width: 1000px;
  border-radius: 13px;
}
.wrapper .icon {
  position: absolute;
  top: 0;
  height: 100%;
  align-items: center;
}
.icon:first-child {
  left: 0;
  display: none;
}
.icon:last-child {
  right: 0;
  justify-content: flex-end;
}

.wrapper .tabs-box,
.wrapper .tabs-box2 {
  display: flex;
  gap: 12px;
  list-style: none;
  overflow-x: hidden;
  scroll-behavior: smooth;
}
.tabs-box.dragging,
.tabs-box2.dragging {
  scroll-behavior: auto;
  cursor: grab;
}
.tabs-box .tab,
.tabs-box2 .tab {
  cursor: pointer;
  white-space: nowrap;
  user-select: none;
}
.tabs-box.dragging .tab,
.tabs-box2.dragging .tab {
  user-select: none;
  pointer-events: none;
}

.dropdown:hover .dropdown-menu {
  display: block;
}
