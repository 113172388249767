html {
  font-family: sans-serif;
  font-size: 14px;
}

table {
  border-left: 0.5px solid #f1f1f2;
  border-right: 0.5px solid #f1f1f2;
}

tbody {
  border-left: 0.5px solid #f1f1f2;
  border-right: 0.5px solid #f1f1f2;
}

th {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: start;
  padding-left: 20px;
  height: 34px;
  color: #020b17;
  font-size: 12px;
  font-weight: 500;
  line-height: 150%;
  background: #f5f6fa;
  box-shadow: 0px 1px 0px 0px #e8e8e8;
}

td {
  padding: 12px 20px;
}

@media (max-width: 768px) {
  td {
    padding: 5px 12px;
  }
  th {
    padding-left: 10px;
  }
}
/* tr:nth-child(odd){
    background-color: red!important
  } */

tfoot {
  color: gray;
}

tfoot th {
  font-weight: normal;
}

th:first-child {
  /* position: sticky; */
  left: 0;
  z-index: 0.5; /* Ensures sticky column is above scrollable content */
}

th:last-child {
  /* position: sticky; */
  right: 0;
  z-index: 0.5; /* Ensures sticky column is above scrollable content */
}

th:nth-child(2) {
  /* position: sticky; */
  left: 55px;
  z-index: 0.5; /* Ensure it has the same z-index for consistency */
}
