@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.rbc-row-content {
  z-index: 0 !important;
}
/* Toggle B */
input:checked ~ .dot {
  transform: translateX(100%);
}
.downloadBtn {
  width: 50px;
  height: 90%;
  margin-right: 10px;
  border: none;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  transition-duration: 0.3s;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.11);
}

.svgIcon {
  fill: white;
}

.icon2 {
  width: 18px;
  height: 3px;
  border-bottom: 2px solid white;
  border-left: 2px solid white;
  border-right: 2px solid white;
}

.tooltip {
  position: absolute;
  right: 55px;
  opacity: 0;
  background-color: rgb(12, 12, 12);
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition-duration: 0.2s;
  pointer-events: none;
  letter-spacing: 0.5px;
  width: max-content;
}

.downloadBtn:hover .tooltip {
  opacity: 1;
  transition-duration: 0.3s;
}

.downloadBtn:hover {
  background-color: rgb(150, 94, 255);
  transition-duration: 0.3s;
}

.downloadBtn:hover .icon2 {
  border-bottom: 2px solid rgb(235, 235, 235);
  border-left: 2px solid rgb(235, 235, 235);
  border-right: 2px solid rgb(235, 235, 235);
}

.downloadBtn:hover .svgIcon {
  fill: rgb(255, 255, 255);
  animation: slide-in-top 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@keyframes slide-in-top {
  0% {
    transform: translateY(-10px);
    opacity: 0;
  }

  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

.react-toggle-track {
  background-color: white !important;
  border: 1px solid #9a9a9a !important;
}
.react-toggle--checked .react-toggle-track {
  background-color: white !important;
  border: 1px solid #335fe4 !important;
}

.react-toggle--checked .react-toggle-thumb {
  background-color: #335fe4 !important;
  left: 28px !important;
}

.react-toggle-thumb {
  top: 3px !important;
  left: 4px !important;
  width: 18px !important;
  height: 18px !important;
  background-color: #9a9a9a !important;
  border: none !important;
}

.react-toggle--focus .react-toggle-thumb,
.react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
  box-shadow: none !important;
}

.rbc-toolbar {
  padding-left: 25px !important;
    padding-right: 25px !important;
}

.Dropdown-root {
  position: relative;
  width: auto !important;
  margin-right: 1rem;
}

.rbc-btn-group {
  display: inline-block;
  white-space: normal !important;
}





@media(max-width: 900px) {
  .rbc-toolbar-label {
    margin-top: 10px;
    margin-bottom: 10px;
  }

 .Dropdown-root {
  width: 100% !important;
  margin-right: 0rem;
 }
}

/* animation for page loader  */
@keyframes move {
  0% {
    background-color: transparent;
  }
  50% {
    background-color: #3b82f6;
  }
  100% {
    background-color: transparent;
  }
}

.gradient-border {
  position: relative;
}

.gradient-border::before {
  content: "";
  position: absolute;
  inset: 0;
  padding: 1.5px;  /* Thickness of the border */
  border-radius: 20px; /* Matching rounded corners */
  background: linear-gradient(92.4deg, #0E3DBF 0%, #2FCFF2 100%);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  pointer-events: none;
}
